import axios from "axios";

// let path = "";

// fetch("./path.json")
//   .then(function (res) {
//     return res.json();
//   })
//   .then(function (data) {
//     path = data.prodAPI;
    
//   })
//   .catch(function (err) {
//     console.log(err, " error");
//   });

const instance = axios.create({
  //baseURL: 'https://react-my-burger-cea78.firebaseio.com/'http://smms/API
  //baseURL:'http://smms/API/'

  //baseURL:'https://localhost:44338/'
  baseURL:'/API/'

  //baseURL:'http://172.106.164.55:82/'

  //http://localhost:3000/Rooms/GetMasterChangeStatusList
  //https://localhost:44338/Rooms/GetMasterChangeStatusList
  //http://localhost:3000/API/Rooms/GetMasterChangeStatusList
});

export default instance;
